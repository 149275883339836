.dual-button-toggle {
  display: flex;
  align-items: center;
  padding: 6px;
  background: #EAE8FA;
  width: fit-content;
  border-radius: 6px;

  .button {
    background: transparent;
    padding: 9px;
    color: #334155;

    &.selected {
      background: #FFFFFF;
      color: #0F172A;
    }
  }
}
