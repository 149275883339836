.chat-msg-container {
  display: flex;
  flex-direction: column;
  font: 400 14px Inter, 'Arial', sans-serif;

  @media (max-width: 420px) {
    flex-direction: column;
  }
  
  .chat-msg-body {
    display: flex;
    white-space: pre-wrap;
    margin-bottom: 10px;

    .chat-message {
      max-width: 400px;
      width: 400px;
      padding: 10px 20px;
      width: fit-content;
      border-radius: 6px;
      line-height: 15px;
      font-weight: 300;

      @media (max-width: 980px) {
        max-width: 320px;
        width: 320px
      }

      @media (max-width: 800px) {
        max-width: 280px;
        width: 280px;
      }

      @media (max-width: 700px) {
        max-width: 240px;
        width: 240px;
      }

      @media (max-width: 600px) {
        max-width: 280px;
        width: 280px;
      }

      @media (max-width: 420px) {
        max-width: 240px;
        width: 240px;
        font-size: 14px;
      }

      @media (max-width: 360px) {
        max-width: 220px;
        width: 220px;
      }

      &.receiver {
        background-color: #FFFFFF;
        border: 1px solid #E2E8F0;
        margin: 7px 10px 7px 20px;
        border-radius: 8px;

        @media (max-width: 420px) {
          margin: 7px 10px 0 20px;
        }
      }

      &.sender {
        background-color: #EAE8FA;
        border: 1px solid #E2E8F0;
        margin: 7px 20px 7px 10px;
        border-radius: 8px;
        order: 1;
        display: flex;
        justify-content: flex-end;

        @media (max-width: 420px) {
          order: 0;
          align-self: flex-end;
          margin: 7px 20px 0 10px;
        }
      }
    }
  }
}