.characteristics-node {
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 10px;
  justify-content: center;

  .age-node {
    font: 700 16px/24px 'Inter';
    color: #64748B;
  }
}
