.chat-history {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 20px;
  overflow-y: scroll;
  border-radius: 10px;
  padding: 30px;
  row-gap: 1rem;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1240px) {
    padding: 20px;
  }

  @media(max-width: 980px) {
    padding: unset;
    background-color: #FFFFFF;
    border-radius: unset;
    height: 100%;
  }
}