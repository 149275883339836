.chat-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  background-color: #FFFFFF;

  @media (max-width: 480px) {
    bottom: 60px;
    &.hidden {
      display: none;
    }
  }
  
  .chat-bar {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 8px;
    height: 140px;
    width: 100%;
    box-sizing: border-box;

    @media (max-width: 980px) {
      width: 90%;
      padding: unset;
    }

    .inputs {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 100%;

      .chat-input {
        width: 100%;
        height: 100%;
        outline: none;
        padding: 10px;
        box-sizing: border-box;
        background-color: transparent;
        border: 1px solid #e2e8f0;
        border-radius: 8px;
        resize: none;
        font: 400 14px Inter, 'Arial', sans-serif;
  
        &::-webkit-scrollbar {
          display: none;
        }
      }
  
      .chat-input-content {
        height: 100%;
      }

      .input-menu-controls {
        display: flex;
        align-items: center;
        height: 25px;
        box-sizing: border-box;
        padding: 0 10px;
        column-gap: 1rem;
      }
    }

    .chat-input-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 10px;
    }
  }
}