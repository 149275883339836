.inbox-card {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  padding: 15px;
  box-sizing: border-box;
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  cursor: pointer;

  &.selected {
    background-color: #F4F4F5;
  }

  .inbox-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .inbox-recipient {
      display: flex;
      column-gap: 10px;
      font-weight: 600;
    }

    .inbox-timestamp {
      font-weight: 300;
      font-size: 12px;
    }
  }
  
  .inbox-card-body {
    display: flex;
    justify-content: flex-start;
    color: rgba(0, 0, 0, 0.6);
  }

  .inbox-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button {
      padding: unset;
    }
  }
}