.dashboard-metric-list-container {
  display: flex;
  flex-flow: column;
  gap: 12px;

  .metric-title {
    font: 600 16px Inter, 'Arial', sans-serif;
  }

  .dashboard-metric-list {
    display: flex;
    flex-flow: wrap;
    gap: 20px;
  }
}