.tooltip {
  display: flex;
  position: absolute;
  z-index: 9000;
  
  .tooltip-content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: #231F20;
    color: #FFFFFF;
    font: 500 15px Inter, Arial, sans-serif;
    width: max-content;
    padding: 7px 10px;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(30, 41, 59, 0.25);
  }
}
