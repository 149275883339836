.lead-conversation {
  width: calc(100vw - 760px);
  font: 400 16px Inter, 'Arial', sans-serif;
  max-width: 1500px;

  box-sizing: border-box;
  padding: 30px 24px 15px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;


  border: 1px solid #E2E8F0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 6px;


  @media (max-width: 1280px) {
    width: calc(100vw - 710px);
  }
  
  .lead-conversation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 25px 0;
    width: 100%;
    border-bottom: 2px solid #E2E8F0;

    .conversation-contact-buttons {

    }
  }
}