.recover-clients-manage-page {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin: 20px 30px;
  padding-bottom: 100px;

  .page {
    display: flex;
    flex-flow: column;

    .recover-clients-manage-page-header {
      position: relative;
      
      .title { 
        margin: 0 0 15px 0;
        font: 600 24px Inter, 'Arial', sans-serif;
      }
    
      .subtitle {
        font: 400 16px Inter, 'Arial', sans-serif;
        margin: 0 0 20px 0;
      }
      
      .recover-clients-button-dashboard {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
      }

      .fine-tune-message-buttons {
        display: flex;
        column-gap: 20px;

        .file-upload-button .button-content .upload-icon {
          filter: brightness(0%);
        }

        .flatfile_iframe-wrapper iframe {
          height: 100% !important;
        }
        
        .show-completed-toggle {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }

      .filter-bar {
        display: flex;
        column-gap: 20px;
      }

      .input-subtitle {
        font: 600 14px Inter, 'Arial', sans-serif;
        margin: 0;
      }

      .eligible-clients-count {
        font: 600 14px Inter, Arial, sans-serif;
      }
    }

    .recover-clients-page-content {
      display: flex;
      gap: 10px;
      flex-flow: column;
      margin-top: 30px;
    }
  }
}
