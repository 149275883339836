.chat-timestamp {
  margin: 10px auto;
  text-align: center;
  font-size: 12px;
  display: flex;
  align-self: center;

  &.receiver {
    margin: 10px 0 10px auto;

    @media (max-width: 420px) {
      margin: 0 auto 0 20px; 
      font-size: 10px;
    }
  }

  &.sender {
    margin: 10px auto 10px 0;

    @media (max-width: 420px) {
      margin: 0 20px 0 auto;
      font-size: 10px;
    }
  }
}