.generic-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;

  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
  }

  .checkbox-container {
    width: 16px;
    height: 16px;
    border: 1px solid #231F20;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;

    .icon {
      width: 12px;
      height: 12px;
    }
  }

  .label-text {
    margin-left: 5px;
  }

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    &:hover {
      cursor: auto;
    }
  }

  &.invert-label {
    .label-text {
      order: 1;
    }

    .checkbox-container {
      order: 2;
    }

    input[type="checkbox"] {
      order: 3;
    }
  }
}