.lead-inbox-page {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin: 20px 30px;
  font: 400 14px Inter, 'Arial', sans-serif;
  height: calc(100vh - 80px);
  position: relative;

  .page {
    display: flex;
    gap: 24px;
    height: 100%;

    .lead-inbox-body {
      display: flex;
      flex-flow: column;
      height: 100%;

      .lead-inbox {
        display: flex;
        .lead-inbox-header {
          .title { 
            margin: 0 0 15px 0;
            font: 600 28px Inter, 'Arial', sans-serif;
          }
        
          .subtitle {
            font: 400 18px Inter, 'Arial', sans-serif;
            margin: 0 0 30px 0;
          }
        }
      }
  
      .lead-page-content {
        display: flex;
        gap: 30px;
        height: calc(100% - 98.5px);
      }
    }
  }
}