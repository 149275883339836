.conversation-contact-details {
  height: 70px;
  .contact-name {
    font-weight: 600;
    margin: 8px 0;
  }

  .contact-channel-details, .chat-recipient-link {
    font-size: 12px;
    margin: 0;
    color: rgba(0, 0, 0, 0.6);
  }

  .contact-channel {
    font-size: 12px;
    margin: 0;
    color: rgba(0, 0, 0, 0.6);
  }
}