.lead-inbox-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 350px;

  @media (max-width: 1280px) {
    width: 300px;
  }

  .lead-inbox-list-header {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin-bottom: 15px;
    align-items: flex-end;

    .search-container {
      width: 100%;
    }
  }

  .lead-inbox-list-body {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    min-width: 0;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}